







































import { Component, Vue, Prop } from 'vue-property-decorator';
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable } from 'vue-good-table';

@Component({
  components: {
    VueGoodTable,
  },
})
export default class BaseTable extends Vue {
  @Prop() private columns!: any;
  @Prop() private rows!: any;
}
