

























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AppModule, Customer, hotelConverter, customerConverter } from '@/store/app'
import firebase from 'firebase/app';
import 'firebase/firestore';
import fs = firebase.firestore;
const db = firebase.firestore();

import { dateToFormatString } from '@/dateToFormatString'
import BaseInput from '@/components/BaseInput.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseTable from '@/components/BaseTable.vue'
import Panel from '@/components/Panel.vue'
@Component({
  components: {
    BaseInput,
    BaseButton,
    BaseTable,
    Panel
  },
})
export default class CustomersView extends Vue {
  private customer = {} as Customer;

  private get hotel() {
    return AppModule.hotel;
  }

  private columns: Array<{ [key: string]: string | Function }> = [
    {
      label: this.$i18n.t('customer.isCheckedIn').toString(),
      field: 'isCheckedIn',
    },
    {
      label: this.$i18n.t('customer.thName').toString(),
      field: 'name',
    },
    {
      label: this.$i18n.t('customer.thArrivalDate').toString(),
      field: this.getArrivalDateColumn,
    }
  ];

  private get rows() {
    return AppModule.customers;
  }

  private get todayRows() {
    return AppModule.customers.filter(v=>{
      return v.arrivalDate.toDateString() == new Date().toDateString();
    });
  }

  private get todayCheckedIn() {
    return this.todayRows.filter(v=>{
      return v.isCheckedIn;
    });
  }

  mounted() {
    firebase.auth().onAuthStateChanged(user => {
      AppModule.setCurrentUser(user);
      if (user) {
        this.getCustomClaimRole();
        const hotelRef = db.collection('hotels').doc(user.uid).withConverter(hotelConverter);
        hotelRef.get().then((hotel)=>{
          AppModule.setHotel(hotel.data()!);
        });

        db.collection('hotels').doc(user.uid).collection("customers").withConverter(customerConverter).get().then((customers)=>{
          const tmp = [] as Customer[];
          customers.forEach((customer)=>{
            tmp.push(customer.data());
          });
          AppModule.setCustomers(tmp);
        });
      }else{
        this.$router.push('/manage/signin');
      }
    });
  }

  private onRowClick(params: any) {
    this.$router.push(`/manage/customer/${params.row.id}`);
  }

  private async getCustomClaimRole() {
    await firebase.auth().currentUser!.getIdToken(true);
    const decodedToken = await firebase.auth().currentUser!.getIdTokenResult();
    if(!decodedToken.claims.stripeRole)this.$router.push('/manage/payment');
  }

  private getArrivalDateColumn(customer: Customer){
    return this.getDateString(customer.arrivalDate);
  }

  private getDateString(date: Date){
    return dateToFormatString(date, this.$t('dateFormat').toString());
  }
}
